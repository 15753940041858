.formComponent {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex; /* Make the formComponent a flex container */
  flex-direction: column; /* Stack child elements (rows) vertically */
}

/* Style for each row in your form */
.formRow {
  display: flex; /* Make each row a flex container */
  flex-wrap: wrap; /* Allow items to wrap to next line if not enough space */
  margin-bottom: 10px; /* Add some space between rows */
}

/* Style for each field container within a row */
.fieldContainer {
  flex: 1; /* Allow each field container to grow and fill available space */
  padding: 0 10px; /* Add some padding around each field for spacing */
}

.submitButton {
  background-color: #9B51E0 !important;
  color: #fff !important;
  transition: background-color 0.3s;
  width: 30%;
  margin: 20px auto 0; /* Center button with top margin */
}

.submitButton:hover {
  background-color: #8742c6 !important;
}

.submitButton:active {
  background-color: #6e33a1 !important;
}

.utmViewerWrapper {
  background-color: #f5f5f5;
  padding: 20px 40px 30px;
  border-radius: 0px;
  margin: 20px -32px; /* Extend to the edges of the formComponent */
}
